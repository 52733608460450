table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

th, td {
  padding: 10px;
  text-align: left;
  border: none;
}

.sponsor-level td {
  border: none;
  border-bottom: 1px solid #dee2e6;
  font-weight: bold;
}

.centered {
  text-align: center;
  border: none;
}

td img {
  width: calc(100% - 30px);
  height: auto;
  margin: 30px;
}